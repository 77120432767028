import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PayPerks is proud to be included in Poets and Quants’ first annual list of top MBA Startups. In good company, to boot: congrats to our friends at Warby Parker, SoFi, Rent the Runway, Blue Apron, FSA Store and Olapic as well!`}</p>
    <p><a parentName="p" {...{
        "href": "http://poetsandquants.com/2013/11/18/poetsquants-top-100-mba-startups"
      }}>{`http://poetsandquants.com/2013/11/18/poetsquants-top-100-mba-startups`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      